import {
  Cell,
  Select,
  Modal,
  Placeholder,
  List,
  FixedLayout,
  Banner,
  Button,
  Radio,
  Input,
  Section,
} from '@telegram-apps/telegram-ui';
import {
  GreenCardOptions,
  getNextThreeDaysForAPI,
  getNextThreeDays,
  isNumeric,
  validateIDNO,
  validateIDNP,
  formatDateForAPI,
} from './constants';
import React, { useEffect, useState } from 'react';
import { getOffers } from '../../api/getOffers';
import { Offer } from '../../interfaces/offer';
import {
  capitalizeFirstLetter,
  formatDateRange,
  isValidDate,
} from '../../utils/shared';
import { createOrder } from '../../api/createOrder';
import { DateInput } from '../../components/shared/DateInput';

export const GreenCardForm = () => {
  const [buttonText, setButtonText] = useState('Calculeazǎ');
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isConfirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  const [isConfirmButtonLoading, setConfirmButtonLoading] = useState(false);
  const [isDateInputValid, setIsDateInputValid] = useState(true);
  const [isFinalDateValid, setIsFinalDateValid] = useState(false);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [offers, setOffers] = useState<{ offers: Offer[] }>({ offers: [] });
  const [idnxStatus, setIdnxStatus] = useState<
    'default' | 'error' | 'focused' | undefined
  >('default');
  const [certificateNumberStatus, setCertificateNumberStatus] = useState<
    'default' | 'error' | 'focused' | undefined
  >('default');
  const [idnxErrorMessage, setIdnxErrorMessage] = useState('');
  const [carSummary, setCarSummary] = useState('');

  const [formData, setFormData] = useState({
    region: '',
    idnx: '',
    certificateNumber: '',
    duration: '15',
    startDate: getNextThreeDaysForAPI()[0],
    startDateData: '',
    company: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    window!.Telegram!.WebApp.BackButton.isVisible = true;
    window!.Telegram!.WebApp.BackButton.onClick(() => {
      window!.location!.href = '/';
    });
  }, []);

  const validateIdnx = (e) => {
    const isIdno = validateIDNO(e.target.value);
    const isIdnp = validateIDNP(e.target.value);
    if (isIdno || isIdnp) {
      setIdnxStatus('default');
      setIdnxErrorMessage('');
    } else {
      setIdnxStatus('error');
      setIdnxErrorMessage('Codul introdus nu este valid');
    }
    if (e.target.value.length === 13) {
      if (!isIdno && !isIdnp) {
        window!.Telegram!.WebApp.HapticFeedback.notificationOccurred('error');
      }
    }
  };

  const validateCertificateNumber = (e) => {
    const certificateNumber = e.target.value;
    if (
      certificateNumber.toString().length === 9 &&
      isNumeric(certificateNumber)
    ) {
      setCertificateNumberStatus('default');
    } else {
      setCertificateNumberStatus('error');
    }
  };

  useEffect(() => {
    const isValid = isValidDate(formData.startDateData);
    setIsDateInputValid(isValid);
    if (formData.startDate === 'otherday' && isDateInputValid) {
      setIsFinalDateValid(true);
    } else if (getNextThreeDaysForAPI().includes(formData.startDate)) {
      setIsFinalDateValid(true);
    } else setIsFinalDateValid(false);
  }, [formData.startDate, formData.startDateData, isDateInputValid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!window.Telegram?.WebApp) {
      return;
    }
    if (
      (formData.region === 'UA' || formData.region === 'EU') &&
      formData.duration.length !== 0 &&
      formData.certificateNumber.length !== 0 &&
      formData.idnx.length !== 0 &&
      certificateNumberStatus !== 'error' &&
      idnxStatus !== 'error' &&
      isFinalDateValid
    ) {
      setButtonDisabled(true);
      setButtonLoading(true);
      getOffers(
        formData.certificateNumber,
        parseInt(formData.duration),
        formData.region,
        formData.idnx
      ).then((res) => {
        if (res.hasOwnProperty('error')) {
          setButtonLoading(false);
          if (res.error === 'Vehicle not found') {
            setCertificateNumberStatus('error');
            setButtonText('Vehicul invalid');
            window!.Telegram!.WebApp.HapticFeedback.notificationOccurred(
              'error'
            );
          }
          return;
        }
        setButtonText(`${res.offers.length} oferte`);
        window!.Telegram!.WebApp.HapticFeedback.notificationOccurred('success');
        setOffers(res);
        setButtonLoading(false);
        setButtonDisabled(false);
      });
    } else {
      setButtonText('Calculeazǎ');
      setButtonDisabled(true);
      setButtonLoading(false);
      setConfirmButtonDisabled(true);
    }
  }, [
    formData.certificateNumber,
    formData.duration,
    formData.idnx,
    formData.region,
    formData.startDate,
    formData.startDateData,
    isDateInputValid,
    certificateNumberStatus,
    idnxStatus,
    isFinalDateValid,
  ]);

  useEffect(() => {
    setFormData({
      ...formData,
      company: '',
    });
    setConfirmButtonDisabled(true);
    const carInfo = offers?.offers[0]?.name.split(', ').pop() || '';
    setCarSummary(carInfo);
  }, [offers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formData.company !== '') {
      setConfirmButtonDisabled(false);
    } else {
      setConfirmButtonDisabled(true);
    }
  }, [formData.company]);
  const handleSubmit = () => {
    try {
      const { certificateNumber, region, duration, startDate, idnx, company } =
        formData;
      setConfirmButtonLoading(true);
      setConfirmButtonDisabled(true);
      const date =
        startDate === 'otherday'
          ? formatDateForAPI(formData.startDateData)
          : startDate;
      createOrder(
        '',
        date,
        certificateNumber,
        parseInt(duration),
        region,
        idnx,
        company
      ).then((res) => {
        window!.location!.href = `/order?order=${res.id}`;
      });
    } catch (err) {
      setConfirmButtonLoading(false);
    }
  };
  return (
    <form className="w-full">
      <List
        style={{
          background: 'var(--tgui--secondary_bg_color)',
          height: '100vh',
        }}
      >
        <Section header="Zona de deplasare" className="pt-4 mt-12">
          <Cell
            Component="label"
            before={
              <Radio
                name="region"
                value="UA"
                onChange={(e) => {
                  handleChange(e);
                  window!.Telegram!.WebApp.HapticFeedback.impactOccurred(
                    'light'
                  );
                }}
              />
            }
            multiline
          >
            Ucraina
          </Cell>
          <Cell
            Component="label"
            before={
              <Radio
                name="region"
                value="EU"
                onChange={(e) => {
                  handleChange(e);
                  window!.Telegram!.WebApp.HapticFeedback.impactOccurred(
                    'light'
                  );
                }}
              />
            }
            multiline
          >
            Toate țările sistemului „CarteVerde” (UE)
          </Cell>
        </Section>
        <Section header="Perioada asiguratǎ">
          <Select
            name="duration"
            value={formData.duration}
            onChange={(e) => {
              handleChange(e);
              window!.Telegram!.WebApp.HapticFeedback.impactOccurred('light');
            }}
          >
            {GreenCardOptions.map((val) => {
              return (
                <option value={val.value} key={val.value}>
                  {val.title}
                </option>
              );
            })}
          </Select>
        </Section>
        <Section header="Data începerii asigurǎrii">
          <Select
            // header="Data începerii asigurǎrii"
            name="startDate"
            value={formData.startDate}
            onChange={(e) => {
              handleChange(e);
              window!.Telegram!.WebApp.HapticFeedback.impactOccurred('light');
            }}
          >
            <option value={getNextThreeDaysForAPI()[0]}>
              Azi ({getNextThreeDays()[0]})
            </option>
            <option value={getNextThreeDaysForAPI()[1]}>
              Mâine ({getNextThreeDays()[1]})
            </option>
            <option value={getNextThreeDaysForAPI()[2]}>
              Poimâine ({getNextThreeDays()[2]})
            </option>
            <option value="otherday">Altǎ zi ...</option>
          </Select>
        </Section>
        {formData.startDate === 'otherday' && (
          <DateInput
            name="startDateData"
            value={formData.startDateData}
            isValid={isDateInputValid}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        )}
        <Section header="Cod Personal IDNP/IDNO">
          <Input
            placeholder="Ex: 2000400070720"
            name="idnx"
            type="text"
            inputMode="numeric"
            maxLength={13}
            status={idnxStatus}
            value={formData.idnx}
            onChange={(e) => {
              handleChange(e);
              validateIdnx(e);
            }}
          />
        </Section>
        <Section header="Numǎr/Serie certificat înmatriculare">
          <Input
            placeholder="Ex: 200200200"
            type="text"
            inputMode="numeric"
            maxLength={9}
            name="certificateNumber"
            value={formData.certificateNumber}
            status={certificateNumberStatus}
            onChange={(e) => {
              handleChange(e);
              validateCertificateNumber(e);
            }}
          />
        </Section>
        <div style={{ height: '70px' }}></div>
        <Modal
          header={<Modal.Header />}
          trigger={
            <FixedLayout
              style={{
                padding: 16,
                backgroundColor: 'var(--tgui--secondary_bg_color)',
              }}
            >
              <Button
                size="l"
                stretched
                disabled={isButtonDisabled}
                loading={isButtonLoading}
              >
                {buttonText}
              </Button>
            </FixedLayout>
          }
        >
          <Placeholder style={{ padding: '0px', alignItems: 'start' }}>
            <List>
              <Banner
                className="w-full"
                callout="Vehicul:"
                header={carSummary}
              ></Banner>
              <Banner
                className="w-full"
                callout="Perioada asiguratǎ:"
                header={formatDateRange(
                  formData.startDate,
                  formData.duration,
                  formData.startDateData,
                  isDateInputValid
                )}
              ></Banner>
            </List>
            <Section header="Compania de asigurare" className="p-0 w-full">
              {offers.offers.map((offer: Offer) => {
                return (
                  <Cell
                    className="w-full"
                    Component="label"
                    before={
                      <>
                        <Radio
                          name="company"
                          value={offer.company}
                          checked={formData.company === offer.company}
                          onChange={(e) => {
                            handleChange(e);
                            window!.Telegram!.WebApp.HapticFeedback.impactOccurred(
                              'light'
                            );
                          }}
                        />
                        <img
                          className="ml-3"
                          width={40}
                          src={`./images/${capitalizeFirstLetter(
                            offer.company
                          )}.png`}
                          alt={`${capitalizeFirstLetter(offer.company)}`}
                        />
                      </>
                    }
                    multiline
                    after={
                      <small className="max-w-[100px] text-center">
                        <b>{offer.price} MDL</b>
                        <div className="opacity-80">
                          ({offer.reference_price.replace(' EUR', '')} €)
                        </div>
                      </small>
                    }
                  >
                    {capitalizeFirstLetter(offer.company)}
                  </Cell>
                );
              })}
            </Section>
            <div className="p-[16px] w-full">
              <Button
                size="l"
                stretched
                type="submit"
                onClick={handleSubmit}
                disabled={isConfirmButtonDisabled}
                loading={isConfirmButtonLoading}
              >
                Confirmǎ
              </Button>
            </div>
          </Placeholder>
        </Modal>
      </List>
    </form>
  );
};
