import {
  Title,
  Text,
  List,
  Section,
  Cell,
  Avatar,
} from '@telegram-apps/telegram-ui';
import { useState, useEffect } from 'react';
import { useTelegram } from '../context/TelegramContext';
import { Link } from 'react-router-dom';
// import { Icon28Stats } from '@telegram-apps/telegram-ui/dist/icons/28/stats';
const insuranceItems = [
  {
    title: 'Carte Verde',
    subtitle: 'Asigurare carte verde',
    href: '/green-card',
  },
  {
    title: 'Rovinieta',
    subtitle: 'Rovinieta România',
    href: '#rovinieta',
  },
  {
    title: 'RCA',
    subtitle: 'Asigurare auto obligatorie',
    href: '#rca',
  },
];

export const Homepage = () => {
  useEffect(() => {
    window!.Telegram!.WebApp.BackButton.isVisible = false;
  }, []);

  return (
    <>
      <div
        style={{
          background: 'var(--tgui--secondary_bg_color)',
          height: '100vh',
        }}
      >
        <div className="text-center">
          <div>
            <img
              src="./images/iasig-small-logo.png"
              className="max-w-20 mx-auto py-8"
              alt="iAsig logo"
            />
          </div>
          <Text>Asigurări online</Text>
          <Title level="1" weight="1" className="py-1">
            iAsig
          </Title>
          <Text
            style={{
              color: 'var(--tgui--hint_color)',
            }}
          >
            Emitere instantă, format electronic
          </Text>
        </div>
        <List
          className="mt-20"
          style={{
            background: 'var(--tgui--secondary_bg_color)',
            padding: 10,
          }}
        >
          <Section>
            {insuranceItems.map((item, index) => (
              <Link to={item.href}>
                {' '}
                <Cell
                  key={index}
                  subtitle={item.subtitle}
                  before={<Avatar />}
                  onClick={() =>
                    window!.Telegram!.WebApp.HapticFeedback.impactOccurred(
                      'heavy'
                    )
                  }
                >
                  {item.title}
                </Cell>
              </Link>
            ))}
          </Section>
        </List>
      </div>
    </>
  );
};
