import { Section } from '@telegram-apps/telegram-ui';
import { TelegramTitle } from '../../components/shared/TelegramTitle';
import { GreenCardForm } from './GreenCardForm';

export const GreenCard = () => {
  return (
    <>
      <div style={{ background: 'var(--tgui--secondary_bg_color)' }}>
        <TelegramTitle
          title="Carte Verde"
          subtitle="Asigurare carte verde, format digital. Polița o primiți automat dupǎ
        platǎ."
        />
        {/* <Section className="m-2"> */}
        <GreenCardForm />
        {/* </Section> */}
      </div>
    </>
  );
};
