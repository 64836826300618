import { TelegramProvider, useTelegram } from './context/TelegramContext';
import Layout from './Layout';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Homepage } from './pages/Homepage';
import { GreenCard } from './pages/GreenCard/GreenCard';
import '@telegram-apps/telegram-ui/dist/styles.css';
import { OrderPage } from './pages/Order/OrderPage';

export const App = () => {
  return (
    <Router>
      {/* <AppRoot> */}
        <TelegramProvider>
          <Layout>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/green-card" element={<GreenCard />} />
              <Route path="/order" element={<OrderPage />} />
            </Routes>
          </Layout>
        </TelegramProvider>
      {/* </AppRoot> */}
    </Router>
  );
};
