import {
  Banner,
  Button,
  FixedLayout,
  List,
  Section,
  Skeleton,
} from '@telegram-apps/telegram-ui';
import { TelegramTitle } from '../../components/shared/TelegramTitle';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getOrder } from '../../api/getOrder';
import { InsuranceType } from '../../interfaces/offer';

export const OrderPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [vehicle, setVehicle] = useState('');
  const [duration, setDuration] = useState('');
  const [status, setStatus] = useState('');
  const [region, setRegion] = useState('');
  const [insuranceDescription, setInsuranceDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [currency, setCurrency] = useState('');
  const [isPaymentDisabled, setIsPaymentDisabled] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [insuranceType, setInsuranceType] = useState('');

  const orderParam = searchParams.get('order');

  useEffect(() => {
    window!.Telegram!.WebApp.BackButton.isVisible = true;
    window!.Telegram!.WebApp.BackButton.onClick(() => {
      window!.location!.href = '/';
    });
  }, []);
  const locale = 'ro';

  useEffect(() => {
    try {
      if (orderParam) {
        getOrder(orderParam).then((res) => {
          if (res.hasOwnProperty('error')) {
            setNotFound(true);
            return;
          }
          setNotFound(false);
          const info = res.description.split(', ');
          const carInfo = res.description.split(', ').pop() || '';
          setVehicle(carInfo);
          setDuration(info[1]);
          setRegion(info[2]);
          setInsuranceDescription(info[0]);
          setPrice(res.price);
          setCurrency(res.currency);
          setIsPaymentDisabled(false);
          setStatus(res.status);
          setIsLoaded(true);
          const orderTypeID = orderParam.slice(0, 3);
          setInsuranceType(InsuranceType[orderTypeID]);
        });
      }
    } catch (error) {}
  }, [orderParam]);

  if (notFound) {
    return (
      <>
        <div
          style={{
            background: 'var(--tgui--secondary_bg_color)',
            height: '100vh',
          }}
        >
          <TelegramTitle
            title="Comanda nu a fost gǎsitǎ"
            subtitle="Dacă ai comandat deja, te rugam să verifici confirmarea pe mail sau să ne contactezi."
          />
          <FixedLayout
            style={{
              padding: 16,
              backgroundColor: 'var(--tgui--secondary_bg_color)',
            }}
          >
            <Button
              size="l"
              stretched
              onClick={() => {
                window.location.href = '/';
              }}
            >
              {`Înapoi`}
            </Button>
          </FixedLayout>
        </div>
      </>
    );
  }
  return (
    <>
      <div
        style={{
          background: 'var(--tgui--secondary_bg_color)',
          height: '100vh',
        }}
      >
        <TelegramTitle
          title={orderParam}
          subtitle="Asigurare carte verde, format digital. Polița o primiți automat dupǎ
        platǎ."
        />
        <p className="text-center">
          {' '}
          {status} {insuranceType}
          {process.env.REACT_APP_ENV}
        </p>
        <div>
          <Section header="Detalii comanda" className="p-0 w-full  pt-32">
            <List className="mb-[100px]">
              <Skeleton visible={!isLoaded}>
                <Banner
                  className="w-full"
                  callout="Tipul asigurǎrii:"
                  header={insuranceDescription}
                ></Banner>
                <Banner
                  className="w-full"
                  callout="Vehicul:"
                  header={vehicle}
                ></Banner>
                <Banner
                  className="w-full"
                  callout="Durata:"
                  header={duration}
                ></Banner>
                <Banner
                  className="w-full"
                  callout="Regiunea:"
                  header={region}
                ></Banner>
              </Skeleton>{' '}
            </List>
            <FixedLayout
              style={{
                zIndex: 2,
                padding: 16,
                backgroundColor: 'var(--tgui--secondary_bg_color)',
              }}
            >
              <a
                className="w-full"
                href={`${process.env.REACT_APP_NEXT_PUBLIC_HOST_FUNCTION}/maibData?language=ro&orderId=${orderParam}&insuranceType=${insuranceType}`}
              >
                <Button
                  size="l"
                  stretched
                  loading={!isLoaded}
                  disabled={isPaymentDisabled}
                >
                  {`Plǎteşte (${price} ${currency})`}
                </Button>
              </a>
            </FixedLayout>
          </Section>
        </div>
      </div>
    </>
  );
};

// const Maib = ({
//   orderId,
//   priceEUR,
//   insuranceType,
//   companyName,
// }): JSX.Element => {
//   const handleBeginCheckout = () => {
//     ga.beginCheckout(priceEUR, orderId, ItemName[insuranceType], companyName);
//     fbq.beginCheckout(priceEUR, orderId, ItemName[insuranceType], companyName);
//     fba.beginCheckout(priceEUR, orderId, ItemName[insuranceType], companyName);
//   };
//   const locale = 'ro';
//   return (
//     <a
//       href={`${process.env.NEXT_PUBLIC_HOST_FUNCTION}/maibData?language=${
//         locale || 'ro'
//       }&orderId=${orderId}&insuranceType=${insuranceType}`}
//       rel="noreferrer"
//       target="_blank"
//       onClick={() => handleBeginCheckout()}
//     ></a>
//   );
// };
