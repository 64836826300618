import { createRoot } from 'react-dom/client';
import { App } from './App';
import { setBackgroundAsSecondary } from './helpers/setBackgroundAsSecondary';
import '@telegram-apps/telegram-ui/dist/styles.css';
import { AppRoot } from '@telegram-apps/telegram-ui';

setBackgroundAsSecondary();

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <AppRoot>
    <App />
  </AppRoot>
);
