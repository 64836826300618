import React, { createContext, useContext, useEffect, useState } from 'react';

// Create Context
const TelegramContext = createContext(null);

// Provider Component
export const TelegramProvider = ({ children }) => {
  const [webApp, setWebApp] = useState(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-web-app.js?56';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      if (window.Telegram && window.Telegram.WebApp) {
        const webAppInstance = window.Telegram.WebApp;
        webAppInstance.ready(); // Mark Telegram WebApp as ready
        setWebApp(webAppInstance);
        console.log('Telegram WebApp initialized:', webAppInstance);
      }
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <TelegramContext.Provider value={webApp}>
      {children}
    </TelegramContext.Provider>
  );
};

// Hook to use Telegram WebApp
export const useTelegram = () => useContext(TelegramContext);
