export const getOffers = async (
  vehicle: string,
  duration: number,
  region: string,
  idnx: string
): Promise<any> => {
  const url = 'https://tgworker.iasig.workers.dev/get-offers';
  const body = {
    product: 'green-card',
    region,
    duration,
    vehicle, 
    idnx, 
  };
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(
        `Failed to fetch offers: ${response.status} ${response.statusText}`
      );
    }

    return await response.json(); // Parse and return the JSON object
  } catch (error) {
    console.error('Error fetching offers:', error);
    throw error; // Re-throw the error for further handling
  }
};
