import { Title, Text } from '@telegram-apps/telegram-ui';

export const TelegramTitle = ({ title, subtitle }: any) => {
  return (
    <div className="text-center px-4">
      <div>
        <img
          src="./images/iasig-small-logo.png"
          className="max-w-20 mx-auto py-8"
          alt="iAsig logo"
        />
      </div>
      <Title level="1" weight="1" className="py-1">
        {title}
      </Title>
      <Text
        className="px-2"
        style={{
          color: 'var(--tgui--hint_color)',
        }}
      >
        {subtitle}
      </Text>
    </div>
  );
};
